/* eslint-disable max-len */
import _ from 'lodash';
import { matchPath } from 'react-router-dom';

const ALLOWED_APPLICATION_LANGUAGES = ['FR', 'NL', 'EN'];
const ALLOWED_APPLICATION_CHANNELS = ['NN', 'ING', 'CARDOEN'];
const ALLOWED_APPLICATION_CLIENTS = ['PC', 'BSSO', 'ISSO'];
const URL_CHANNEL_LANGUAGE_CLIENT_PATH = '/:channel/:lang/:client';
const URL_CHANNEL_LANGUAGE_PATH = '/:channel/:lang';
const URL_CHANNEL_PATH = '/:singleParam/';
const DEFAULT_APPLICATION_CHANNEL = 'ING';
const DEFAULT_APPLICATION_LANGUAGE = 'EN';
const DEFAULT_APPLICATION_CLIENT = 'PC';

function isChannelAllowed(channelCode) {
    return ALLOWED_APPLICATION_CHANNELS.indexOf(channelCode) !== -1;
}

function isLanguageAllowed(languageCode) {
    return ALLOWED_APPLICATION_LANGUAGES.indexOf(languageCode) !== -1;
}

function isClientAllowed(clientCode) {
    return ALLOWED_APPLICATION_CLIENTS.indexOf(clientCode) !== -1;
}

function validateChannelAgainstAllowedValues(channelCode) {
    return isChannelAllowed(channelCode) ? channelCode : DEFAULT_APPLICATION_CHANNEL;
}

function validateLanguageAgainstAllowedValues(languageCode) {
    return isLanguageAllowed(languageCode) ? languageCode : DEFAULT_APPLICATION_LANGUAGE;
}

function validateClientAgainstAllowedValues(clientCode) {
    return isClientAllowed(clientCode) ? clientCode : DEFAULT_APPLICATION_CLIENT;
}

// eslint-disable-next-line import/prefer-default-export
export function setLanguageAndChannelFromPath(url) {
    let languageCode;
    let channel;
    let client;

    // First try to Match all three params => That should cover entering webpage from a broker etc.
    const matchAllParams = matchPath(url, {
        path: URL_CHANNEL_LANGUAGE_CLIENT_PATH,
    });

    const matchedAllParams = matchAllParams && matchAllParams;
    if (matchedAllParams) {
        languageCode = matchAllParams.params.lang.toLowerCase();
        channel = matchAllParams.params.channel;
        client = matchAllParams.params.client;
    }

    // Then try to match only language and channel => That should cover page entries from partners i.e ING
    const matchBothParams = matchPath(url, {
        path: URL_CHANNEL_LANGUAGE_PATH,
    });

    const matchedTwoParams = matchBothParams && matchBothParams.isExact;
    if (matchedTwoParams) {
        client = DEFAULT_APPLICATION_CLIENT;
        languageCode = matchBothParams.params.lang.toLowerCase();
        channel = matchBothParams.params.channel;
    }

    // Next we try to match one param only => That covers entries from NN network where only language is specified.
    // To provide best User experience, we will try to set language and locale as per browser setting.
    // Languages covered by application are "fr-FR", "nl-NL", "de-DE" and "en-US"
    // Browser languages could come with different locale settings i.e for France we could have fr, fr-FR, fr-BE etc.
    // To cover that we will take first two browser language letters and create a pattern - 2FirstLetters-2FirstUpperCaseLetters (for example from 'fr' to 'fr-FR')
    // If there is no match default setting will be applied - 'en-US'

    const matchOneParamOnly = matchPath(url, {
        path: URL_CHANNEL_PATH,
    });

    const matchedSingleParam = matchOneParamOnly && matchOneParamOnly.isExact;
    if (matchedSingleParam) {
        const paramValue = matchOneParamOnly.params.singleParam;
        client = DEFAULT_APPLICATION_CLIENT;
        channel = isChannelAllowed(paramValue.toUpperCase()) ? paramValue : DEFAULT_APPLICATION_CHANNEL;
        languageCode = isLanguageAllowed(paramValue.toUpperCase()) ? paramValue : navigator.language.slice(0, 2);
    }

    const matchedNothing = !matchedAllParams && !matchedTwoParams && !matchedSingleParam;
    if (matchedNothing) {
        client = DEFAULT_APPLICATION_CLIENT;
        channel = DEFAULT_APPLICATION_CHANNEL;
        languageCode = navigator.language.slice(0, 2);
    }

    // Validate against possible set of values -> ING and NN for channel and either EN, FR or NL for langage. Small letters included ?
    channel = validateChannelAgainstAllowedValues(channel.toUpperCase());
    languageCode = validateLanguageAgainstAllowedValues(languageCode.toUpperCase());
    client = validateClientAgainstAllowedValues(client.toUpperCase());
    return { languageCode, channel, client };
}
