import { defineMessages } from 'react-intl';

export default defineMessages({
    ingssoPolicySearchTitle: {
        id: 'anonymous.fnol.components.search-policy.ing.Please enter loss details.',
        defaultMessage: 'Please enter loss details.'
    },
    ingssoPolicySearchDocumentTitle: {
        id: 'anonymous.fnol.components.search-policy.ing.To be able to help you faster, please have photos, videos and other documents at hand.',
        defaultMessage: 'To be able to help you faster, please have photos, videos and other documents at hand.'
    },
    ingssoPolicySearchPolicyNumberLabel: {
        id: 'anonymous.fnol.components.search-policy.ing.Policy Number',
        defaultMessage: 'Policy Number'
    },
    ingssoPolicySearchPolicyNumberPlaceholder: {
        id: 'anonymous.fnol.components.search-policy.ing.You can find the policy number on the 1st page of your policy contract. Please provide policy number without any separators (e.g. dashes or any other signs).',
        defaultMessage: 'You can find the policy number on the 1st page of your policy contract. Please provide policy number without any separators (e.g. dashes or any other signs).'
    },
    ingssoPolicySearchDateOfLossLabel: {
        id: 'anonymous.fnol.components.search-policy.ing.Date of loss',
        defaultMessage: 'Date of loss'
    },
    ingssoPolicySearchTimeOfLossLabel: {
        id: 'anonymous.fnol.components.search-policy.ing.Time of loss',
        defaultMessage: 'Time of loss'
    },
    ingssoPolicySearchTimeOfLossTimestamp: {
        id: 'anonymous.fnol.components.search-policy.ing.Do you know at what time the loss occurred?',
        defaultMessage: 'Do you know at what time the loss occurred?'
    },
    ingssoPolicySearchDateOfLossTooltip: {
        id: 'anonymous.fnol.components.search-policy.ing.Please provide the exact date the loss happened. If you do not know, enter the date on which you noticed the damage.',
        defaultMessage: 'Please provide the exact date the loss happened. If you do not know, enter the date on which you noticed the damage.'
    },
    ingssoPolicySearchLetsGetStarted: {
        id: "anonymous.fnol.components.search-policy.ing.Let's get started!",
        defaultMessage: "Let's get started!"
    },
    ingssoPolicySearchPolicyNotFoundError: {
        id: 'anonymous.fnol.components.search-policy.ing.Sorry, the provided data are not valid, please provide correct data.',
        defaultMessage: 'Sorry, the provided data are not valid, please provide correct data.'
    }
});
