/* eslint-disable max-len */
import { useCallback } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withViewModelService } from 'gw-portals-viewmodel-react';
import metadata from './GDPRConsents.metadata.json5';
import styles from './GDPRConsents.module.scss';
// eslint-disable-next-line no-unused-vars
import messages from './GDPRConsents.messages';

function GDPRConsents(props) {
    const {
        history,
        isGDPRConfirmed,
        setisGDPRConfirmed
    } = props;

    const redirectToPrivacyPolicy = useCallback(() => {
        return history.goBack();
    }, [history]);

    const redirectToAssuralia = useCallback(() => {
        return history.goBack();
    }, [history]);

    const overrideProps = {
        fnolSearchPolicyGDPRPrivacyPolicyLink: {
            onClick: redirectToPrivacyPolicy
        },
        fnolSearchPolicyGDPRTextAssuraliaAntiFraudLink: {
            onClick: redirectToAssuralia
        },
        fnolSearchPolicyGDPRConsent: {
            value: isGDPRConfirmed,
            onValueChange: setisGDPRConfirmed
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(
        metadata.componentContent,
        overrideProps,
        resolvers
    );
}

export default withViewModelService(GDPRConsents);
