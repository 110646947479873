import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSummaryAboutProperty: {
        id: 'anonymous.wizard.fnol.pages.summary.About the property',
        defaultMessage: 'About the damage'
    },
    wizardFnolSummaryPropertyRepairDetails: {
        id: 'anonymous.wizard.fnol.pages.summary.Would you like to have the damage repaired through NN repair service or choose to take care of damage yourself?',
        defaultMessage: 'Would you like to have the damage repaired through NN repair service or choose to take care of damage yourself?'
    },
    wizardFnolSummaryPageRepairDetailsPropertyBuildingEstimate: {
        id: 'anonymous.wizard.fnol.pages.property.summary.Building Repair Cost: ',
        defaultMessage: 'Building Repair Cost: '
    },
    wizardFnolSummaryPageRepairDetailsPropertyContentEstimate: {
        id: 'anonymous.wizard.fnol.pages.property.summary.Content Repair Cost: ',
        defaultMessage: 'Content Repair Cost: '
    },
    wizardFnolSummaryPageRepairDetailsPropertyAlreadyRepaired: {
        id: 'anonymous.fnol.summary.estimates.Is the damage already repaired?',
        defaultMessage: 'Has the damage already been repaired?'
    },
    wizardFnolSummaryPageRepairDetailsContentItemsEstimate: {
        id: 'anonymous.fnol.summary.estimates.Content items',
        defaultMessage: 'Content items'
    },
    wizardFnolSummaryPageRepairDetailsContentItemsPurchaseDetails: {
        id: 'anonymous.fnol.summary.estimates.Purchase details',
        defaultMessage: 'Purchase details'
    }
});
