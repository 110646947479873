export default class INGSSOValuesProvider {
    getRedirectionDetails = () => {
        return {
            baseUrlForClient: '/b-auth-policy-search',
            state: { policyNumberEditable: true }
        };
    }

    isBrokerContext = () => {
        return false;
    }

    getTypefilterNameForReporterRelation = () => {
        return 'FrontEndSelectable';
    }

    saveAndResumeEnabled = () => {
        return true;
    }

    getAuthenticationChannel = () => {
        return 'B2C';
    };
}
