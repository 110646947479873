/* eslint-disable max-len */
import _ from 'lodash';
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from 'gw-portals-viewmodel-react';
import { FNOLService } from 'nn-capability-fnol';
import { useValidation } from 'gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import moment from 'moment';
import { ModalNextProvider } from '@jutro/components';
import PartnerContext from '../../contexts/PartnerContext/PartnerContext';
import AuthenticatedContext from '../../contexts/AuthenticatedContext/AuthenticatedContext';
import { redirectToContactCallCenter, redirectToAuthContactCallCenter } from '../../utils/RedirectionUtil';
import PleaseWait from '../../components/PleaseWait/PleaseWait';
import GDPRConsents from '../../components/GDPRConsents/GDPRConsents';
import { getTypecodeTranslation } from '../../utils/TypeCodeTranslationUtil';
import { formatDateToBaseFormat } from '../../utils/DateUtil';
import { getWizardStepBasedOnProgress } from '../../utils/WizardStepToProgressUtil';
import nnCustomMessages from '../../nnCustomMessages';
import metadata from './INGSSOPolicySearch.metadata.json5';
// eslint-disable-next-line no-unused-vars
import messages from './INGSSOPolicySearch.messages';
import styles from './INGSSOPolicySearch.modules.scss';

function INGSSOPolicySearch(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const [noPolicyFoundCounter, setNoPolicyFoundCounter] = useState(0);
    const { history } = props;
    const [policySearchDTO, setPolicySearchDTO] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [timeStampVisibleValue, setTimeStampVisibleValue] = useState();
    const [isGDPRConfirmed, setisGDPRConfirmed] = useState(false);
    const partnerContext = useContext(PartnerContext);

    const {
        authenticationData
    } = useContext(AuthenticatedContext);

    const {
        onValidate,
        isComponentValid,
        registerComponentValidation
    } = useValidation('INGSSOPolicySearch');

    if (_.isEmpty(authenticationData.authenticatedRequestData) || !authenticationData.authenticatedRequestData.authorizationObject || authenticationData.error) {
        // Access to this page should be granted only if there is authentication token and policyNumber in URL path.
        redirectToAuthContactCallCenter(history);
    }

    useEffect(() => {
        const initialDataObject = history.location.state && history.location.state.initialData
            ? history.location.state.initialData
            : {
                policyNumber: authenticationData.authenticatedRequestData.authorizationObject,
                policyTypedManually: false
            };

        const initialSearchDTO = viewModelService.create(
            initialDataObject,
            'cc',
            'com.inlb.cc.extsys.edge.authenticatedFnol.dto.policySelect.InitializeAuthUserFlowDTO'
        );

        return setPolicySearchDTO(initialSearchDTO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty Array as we don't want this code to re-run

    const validateTimeOfLoss = useCallback(() => {
        if (policySearchDTO && policySearchDTO.value) {
            if (timeStampVisibleValue === undefined) return false;

            return timeStampVisibleValue
                ? policySearchDTO.value.lossDate.hour !== undefined
                : true;
        }
        return true;
    }, [policySearchDTO, timeStampVisibleValue]);

    useEffect(() => {
        registerComponentValidation(validateTimeOfLoss);
    }, [registerComponentValidation, validateTimeOfLoss]);

    const onValueChange = useCallback((value, path) => {
        const updatedDTO = viewModelService.clone(policySearchDTO);
        _.set(updatedDTO.value, path, value);
        setPolicySearchDTO(updatedDTO);
    }, [policySearchDTO, viewModelService]);

    const performPolicySearch = useCallback(() => {
        setIsLoading(true);

        policySearchDTO.authRequestDetails = {
            ...authenticationData.authenticatedRequestData,
            authorizationObject: policySearchDTO.policyNumber.value
        };

        FNOLService.retrieveDraftClaimInformation(policySearchDTO.value, history, { partnerContext, translator }).then(async (result) => {
            const selectDTO = policySearchDTO.value;
            // If a draft is not present, start flow from the beginning.
            if (result.claimNumber === undefined) {
                setIsLoading(true);
                return selectDTO;
            }

            // If a draft exists, we ask customer whether he/she wants to start from the beginning or continue.
            const popupResults = await ModalNextProvider.showConfirm({
                title: translator(nnCustomMessages.authenticatedPolicySelectSaveAndResumeDraftClaimPopupTitle),
                message: translator(nnCustomMessages.authenticatedPolicySelectSaveAndResumeDraftClaimPopupBody, {
                    lossCause: getTypecodeTranslation(translator, result.lossCause, 'LossCause'),
                    lossDate: formatDateToBaseFormat(result.lossDate, false)
                }),
                confirmButtonText: translator(nnCustomMessages.authenticatedPolicySelectSaveAndResumeConfirmButton),
                cancelButtonText: translator(nnCustomMessages.authenticatedPolicySelectSaveAndResumeCancelButton)
            });

            // That means customer closed the popup and we shouldn't proceed.
            if (popupResults === 'close') {
                setIsLoading(true);
                return null;
            }

            // Customer decides to start from beginning.
            if (popupResults === 'cancel') {
                setIsLoading(true);
                return selectDTO;
            }

            // Getting here means customer wants to continue registration process based on the draft.
            selectDTO.claimNumber = result.claimNumber;
            setIsLoading(true);
            return selectDTO;
        }).then(async (result) => {
            if (!result) return;

            result.authRequestDetails = {
                ...authenticationData.authenticatedRequestData
            };

            await FNOLService.initializeISSOUserFlow(result, history, { partnerContext, translator }).then(
                (resultDTO) => {
                    if (!resultDTO) return;
                    if (!_.isEmpty(resultDTO.error)) {
                        const { error } = resultDTO;
                        switch (error.errorCode) {
                            case '210': // 210 - Policy not found
                                if (noPolicyFoundCounter >= 3) {
                                    history.push({
                                        pathname: '/quick-flow',
                                        state: {
                                            redirectPath: '/',
                                            initialData: result,
                                            timeStampVisibleValue: timeStampVisibleValue
                                        }
                                    });
                                } else {
                                    setNoPolicyFoundCounter((currentValue) => currentValue + 1);
                                }
                                break;
                            case '211': // 211 - Provided Policy Number is outside Developed LOB's
                                redirectToContactCallCenter(history,
                                    translator(nnCustomMessages.fnolContactCallCenterFromLandingRedirectionErrorTitle),
                                    translator(nnCustomMessages.fnolContactCallCenterFromLandingRedirectionErrorSubTitle),
                                    false, false);
                                break;
                            case '500':
                            default:
                                redirectToAuthContactCallCenter(history, true);
                        }
                    } else {
                        let wizardStepToGoTo = '/fnol';
                        if (resultDTO.progress !== undefined) {
                            wizardStepToGoTo += getWizardStepBasedOnProgress(resultDTO.progress);
                        }

                        resultDTO.authRequestDetails = {
                            ...authenticationData.authenticatedRequestData
                        };

                        history.push({
                            pathname: wizardStepToGoTo,
                            state: {
                                redirectPath: '/',
                                fnolDto: resultDTO
                            }
                        });
                    }
                }
            );
        }).finally(() => {
            setIsLoading(false);
        });
    }, [policySearchDTO, authenticationData.authenticatedRequestData, history,
        partnerContext, translator, noPolicyFoundCounter, timeStampVisibleValue]);

    const overrideProps = {
        policySearchLossDateInput: {
            maxDate: moment().toDate(),
            timeStampVisibleValue: timeStampVisibleValue,
            setTimeStampVisibleValue: setTimeStampVisibleValue
        },
        policySearchSearchButton: {
            disabled: !isComponentValid || !isGDPRConfirmed
        },
        policyNotFoundError: {
            visible: noPolicyFoundCounter > 0
        },
        policySearchContainer: {
            visible: !isLoading
        },
        pageLoader: {
            visible: isLoading
        },
        gdprConsentComponent: {
            history: history,
            isGDPRConfirmed: isGDPRConfirmed,
            setisGDPRConfirmed: setisGDPRConfirmed
        }
    };

    const resolvers = {
        resolveComponentMap: {
            pleaseWait: PleaseWait,
            GDPRConsents
        },
        resolveCallbackMap: {
            onValidate,
            performPolicySearch
        },
        resolveClassNameMap: styles
    };

    return (
        <div>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policySearchDTO}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={onValueChange}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </div>
    );
}

export default withViewModelService(INGSSOPolicySearch);
