import { defineMessages } from 'react-intl';

export default defineMessages({
    authenticatedPolicySelectHeader: {
        id: 'anonymous.authenticated.selectpolicy.About your claim',
        defaultMessage: 'About your claim'
    },
    authenticatedPolicySelectSelectDateOfLoss: {
        id: 'anonymous.authenticated.selectpolicy.Select your date of loss',
        defaultMessage: 'Select your date of loss'
    },
    authenticatedPolicySelectDoYouKnowExactTime: {
        id: 'anonymous.authenticated.selectpolicy.Do you know at what time the loss occurred?',
        defaultMessage: 'Do you know at what time the loss occurred?'
    },
    authenticatedPolicySelectTimeOfLoss: {
        id: 'anonymous.authenticated.selectpolicy.Time of loss',
        defaultMessage: 'Time of loss'
    },
    authenticatedPolicySelectNameLabelOne: {
        id: 'anonymous.authenticated.selectpolicy.Policies found for',
        defaultMessage: 'Policies found for'
    },
    authenticatedPolicySelectNameLabelTwo: {
        id: 'anonymous.authenticated.selectpolicy.on the indicated date of loss',
        defaultMessage: 'on the indicated date of loss'
    },
    authenticatedPolicySelectLabelSelectPolicy: {
        id: 'anonymous.authenticated.selectpolicy.Please select the policy regarding your claim',
        defaultMessage: 'Please select the policy regarding your claim, by clicking on it.'
    },
    authenticatedPolicySelectDateOfLoss: {
        id: 'anonymous.authenticated.selectpolicy.Date of loss',
        defaultMessage: 'Date of loss'
    },
    authenticatedPolicySelectDateOfLossTooltip: {
        id: 'anonymous.authenticated.selectpolicyTooltip.Please provide the exact day the loss happened.',
        defaultMessage: 'Please provide the exact day the loss happened.'
    },
    authenticatedPolicySelectCheckBox: {
        id: 'anonymous.authenticated.selectpolicy.Report claim for another policy',
        defaultMessage: 'Report claim for another policy if not visible in the list above.'
    },
    authenticatedPolicySelectPolicyNumberInput: {
        id: 'anonymous.authenticated.selectpolicy.Your policy number',
        defaultMessage: 'Your policy number'
    },
    authenticatedPolicySelectPolicyNumberInputTooltip: {
        id: 'anonymous.authenticated.selectpolicy.tooltip.You can find the policy number on the first page of your policy contract, on your international insurance card (for a car claim) or in your insurance/bank application. Please provide policy number without any separators (e.g. dashes or any other signs)',
        defaultMessage: 'You can find the policy number on the first page of your policy contract, on your international insurance card (for a car claim) or in your insurance/bank application. Please provide policy number without any separators (e.g. dashes or any other signs)'
    },
    authenticatedPolicySelectPoliciesNotAvailableHeader: {
        id: "anonymous.authenticated.selectpolicy.We didn't find any policies",
        defaultMessage: "We didn't find any policies"
    },
    authenticatedPolicySelectPoliciesNotAvailableHeaderLineTwo: {
        id: 'anonymous.authenticated.selectpolicy.Please enter your policy number below',
        defaultMessage: 'Please enter your policy number below'
    },
    authenticatedPolicySelectErrorText: {
        id: 'anonymous.authenticated.selectpolicy.Sorry, provided data is not valid, please provide correct data.',
        defaultMessage: 'Sorry, provided data is not valid, please provide correct data.'
    },
    authenticatedPolicySelectNextButton: {
        id: 'anonymous.authenticated.selectpolicy.Next',
        defaultMessage: 'Next'
    }
});
