import React, { useContext } from 'react';
import { Link } from '@jutro/components';
import { ImageComponent } from 'gw-components-platform-react';
import ClientContext from '../../../../contexts/ClientContext/ClientContext';
import styles from './INGHeaderLogo.module.scss';

function INGHeaderLogo() {
    const clientTypeContext = useContext(ClientContext);
    const { baseUrlForClient, state } = clientTypeContext.getRedirectionDetails();

    return (
        <div>
            <Link
                to={{ pathname: baseUrlForClient, state: state }}
                className={styles.ingLinkStyles}
            >
                <ImageComponent src="/INGLogoNew.png" className={styles.ingLogosStyle} />
            </Link>
        </div>
    );
}

export default INGHeaderLogo;
