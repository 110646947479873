import { setComponentMapOverrides } from '../../../framework/packages/@jutro/uiconfig';
import ContactCallCenterPage from './pages/ContactCallCenterPage/ContactCallCenterPage';
import FNOLQuickFlowPage from './pages/FNOLQuickFlowPage/FNOLQuickFlowPage';
import FNOLWizard from './components/FNOLWizard/FNOLWizard';
import PageContainer from './components/PageContainer/PageContainer';
import AuthenticationLandingPage from './pages/AuthenticationLandingPage/AuthenticationLandingPage';
import AuthenticatedPolicySelect from './pages/AuthenticatedPolicySelect/AuthenticatedPolicySelect';
import AuthenticatedBrokerPolicySearch from './pages/AuthenticatedBrokerPolicySearch/AuthenticatedBrokerPolicySearch';
import INGSSOPolicySearch from './pages/INGSSOPolicySearch/INGSSOPolicySearch';
import AuthenticatedContactCallCenterPage from './pages/AuthenticatedContactCallCenterPage/AuthenticatedContactCallCenterPage';
import WizardFNOLBasicInformationPage from './pages/WizardFnolBasicInformationPage/WizardFNOLBasicInformationPage';
import WizardFNOLLossDetailsPage from './pages/WizardFnolLossDetailsPage/WizardFNOLLossDetailsPage';
import WizardFNOLRepairPage from './pages/WizardFnolRepairPage/WizardFNOLRepairPage';
import WizardFNOLAdditionalInformationPage from './pages/WizardFnolAdditionalInformationPage/WizardFNOLAdditionalInformationPage';
import WizardFNOLContactDetailsPage from './pages/WizardFnolContactDetailsPage/WizardFNOLContactDetailsPage';
import WizardFNOLSummaryPage from './pages/WizardFnolSummaryPage/WizardFNOLSummaryPage';
import FNOLContext from './components/FNOLWizardContext/FNOLWizardContext';
import FnolConfirmationPage from './pages/FnolConfirmationPage/FNOLConfirmationPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import AssuraliaAntiFraudPage from './pages/AssuraliaAntiFraudPage/AssuraliaAntiFraudPage';
import DatePicker from './components/DatePicker/DatePicker';
import CustomTimePicker from './components/CustomTimePicker/CustomTimePicker';
import CurrencyInput from './components/CurrencyInput/CurrencyInput';
import TitleWidget from './components/TitleWidget/TitleWidget';

const platformComponentMap = {
    customDate: { component: 'customDate' },
    customTimePicker: { component: 'customTimePicker' },
    customCurrency: { component: 'customCurrency' },
    customTitle: { component: 'customTitle' }
};

const platformComponents = {
    customDate: DatePicker,
    customTimePicker: CustomTimePicker,
    customCurrency: CurrencyInput,
    customTitle: TitleWidget
};

setComponentMapOverrides(
    {
        FNOLWizard: { component: 'FNOLWizard' },
        WizardFNOLBasicInformationPage: { component: 'WizardFNOLBasicInformationPage' },
        WizardFNOLLossDetailsPage: { component: 'WizardFNOLLossDetailsPage' },
        WizardFNOLRepairPage: { component: 'WizardFNOLRepairPage' },
        WizardFNOLAdditionalInformationPage: { component: 'WizardFNOLAdditionalInformationPage' },
        WizardFNOLContactDetailsPage: { component: 'WizardFNOLContactDetailsPage' },
        WizardFNOLSummaryPage: { component: 'WizardFNOLSummaryPage' },
        DatePicker: { component: 'customDate' },
        CustomTimePicker: { component: 'customTimePicker' },
        CurrencyInput: { component: 'customCurrency' },
        TitleWidget: { component: 'customTitle' }

    },
    {
        FNOLWizard,
        WizardFNOLBasicInformationPage,
        WizardFNOLLossDetailsPage,
        WizardFNOLRepairPage,
        WizardFNOLAdditionalInformationPage,
        WizardFNOLContactDetailsPage,
        WizardFNOLSummaryPage,
        DatePicker,
        CustomTimePicker,
        CurrencyInput,
        TitleWidget
    }
);

export {
    FNOLContext,
    FnolConfirmationPage,
    FNOLWizard,
    ContactCallCenterPage,
    FNOLQuickFlowPage,
    PageContainer,
    PrivacyPolicyPage,
    AssuraliaAntiFraudPage,
    AuthenticationLandingPage,
    AuthenticatedPolicySelect,
    AuthenticatedBrokerPolicySearch,
    INGSSOPolicySearch,
    AuthenticatedContactCallCenterPage,
    platformComponentMap,
    platformComponents
};
