import { defineMessages } from 'react-intl';

export default defineMessages({
    fnolSearchPolicyGDPRTextAgreeWith: {
        id: 'anonymous.authentication.landingPage.I agree with NN ',
        defaultMessage: 'I agree with the '
    },
    fnolSearchPolicyGDPRPrivacyPolicyLink: {
        id: 'anonymous.authentication.landingPage.privacy policy ',
        defaultMessage: 'Privacy Policy of NN '
    },
    fnolSearchPolicyGDPRTextAnd: {
        id: 'anonymous.authentication.landingPage.and ',
        defaultMessage: ' and '
    },
    fnolSearchPolicyGDPRTextAssuraliaAntiFraudLink: {
        id: 'anonymous.authentication.landingPage.Assuralia ani-fraud statement.',
        defaultMessage: ' the Assuralia anti-fraud statement '
    },
    fnolSearchPolicyGDPRTextInfoLabel: {
        id: 'anonymous.authentication.landingPage.I confirm that all the information provided will be true and accurate to the best of my knowledge.',
        defaultMessage: 'I confirm that all the information provided will be true and accurate to the best of my knowledge.'
    }
});
