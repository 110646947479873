/* eslint-disable max-len */
import React from 'react';
import messages from './INGPartnerValuesProvider.messages';
import DefaultPartnerValuesProvider from '../default/DefaultPartnerValuesProvider';
import INGHeaderLogo from './INGHeader/INGHeaderLogo';
import DefaultHeader from '../default/DefaultHeader/DefaultHeader';

export default class INGPartnerValuesProvider {
    generateHeaderLogo = (translator, redirectionDetails) => {
        return <INGHeaderLogo />;
    }

    generateHeader = () => {
        return <DefaultHeader />;
    }

    generateFooter = (translator) => {
        return new DefaultPartnerValuesProvider().generateFooter(translator);
    }

    partnerPhoneNumber = (translator) => {
        return translator(messages.ingPartnerPhoneNumber);
    }

    partnerUrgentAssistLabel = (translator) => {
        return translator(messages.ingPartnerUrgentHelpLabel, { partnerPhoneNumber: this.partnerPhoneNumber(translator) });
    }

    partnerShortLabelWithPhoneNumber = (translator) => {
        return translator(messages.ingPartnerShortHelpWithNumber, { partnerPhoneNumber: this.partnerPhoneNumber(translator) });
    }

    partnerTypeAllowedForPVBroker = () => {
        return false;
    }
}
