import { defineMessages } from 'react-intl';

export default defineMessages({
    authenticationLandingPageHeader: {
        id: 'anonymous.authentication.landingPage.Welcome at NN myclaim',
        defaultMessage: 'Welcome at NN myclaim'
    },
    authenticationLandingPageHeaderLineTwo: {
        id: 'anonymous.authentication.landingPage.NN helps you to report your damage',
        defaultMessage: 'NN helps you to report your damage'
    },
    authenticationPageItsMeOption: {
        id: 'anonymous.authentication.landingPage.Log in with itsme ®',
        defaultMessage: 'Log in with itsme ®'
    },
    authenticationPageNext: {
        id: 'anonymous.authentication.landingPage.Next',
        defaultMessage: 'Next'
    }
});
